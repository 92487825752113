import React from "react";
import { Helmet } from "react-helmet";

import Header from "src/components/headers/headerHome";
import Footer from "src/components/footers/footer";

import SectionEngagement from "src/components/index/sectionEngagement";
import SectionPartenaire from "src/components/index/sectionPartenaire";
import SectionService from "src/components/index/sectionService";
import SectionHistoire from "src/components/index/sectionHistoire";
import SectionInternational from "src/components/index/sectionInternational";
import SectionAvis from "src/components/index/sectionAvis";
import SectionFaq from "src/components/index/sectionFaq";
import SectionEntreprise from "src/components/index/sectionEntreprise";


export default function index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>art Moval: Déménageurs Lyon - Entreprise de Déménagement</title>
        <meta
          name="description"
          content="La société art Moval située à Tassin-la-Demi-Lune près de Lyon dans le Rhône (69) répond à tous vos besoins en déménagement et garde-meubles."
        />
      </Helmet>


      <header className="h-screen">
        <Header />
      </header>

      <main className="relative">
        <SectionHistoire />
        <SectionService />
        <SectionEntreprise />
        <SectionEngagement />
        <SectionAvis />
        <SectionInternational />
        <SectionFaq />
        <SectionPartenaire />
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}
