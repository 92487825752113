import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function SectionInternational() {
  return (
    //art-moval-international.jpg
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      <div className="absolute inset-0 -z-10 h-full w-full object-cover" >
        <StaticImage
          className="absolute inset-0 -z-10 h-full w-full object-cover"
          src="../../images/art-moval-international.jpg"
          alt="Art Moval International"
        />
      </div>
      <svg
        viewBox="0 0 1097 845"
        aria-hidden="true"
        className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
      >
        <path
          fill="url(#10724532-9d81-43d2-bb94-866e98dd6e42)"
          fillOpacity=".2"
          d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
        />
        <defs>
          <linearGradient
            id="10724532-9d81-43d2-bb94-866e98dd6e42"
            x1="1097.04"
            x2="-141.165"
            y1=".22"
            y2="363.075"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#776FFF" />
            <stop offset={1} stopColor="#FF4694" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        viewBox="0 0 1097 845"
        aria-hidden="true"
        className="absolute left-1/2 -top-52 -z-10 w-[68.5625rem] -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0"
      >
        <path
          fill="url(#8ddc7edb-8983-4cd7-bccb-79ad21097d70)"
          fillOpacity=".2"
          d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
        />
        <defs>
          <linearGradient
            id="8ddc7edb-8983-4cd7-bccb-79ad21097d70"
            x1="1097.04"
            x2="-141.165"
            y1=".22"
            y2="363.075"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#776FFF" />
            <stop offset={1} stopColor="#FF4694" />
          </linearGradient>
        </defs>
      </svg>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Déménagement à l’international depuis une ville de France vers un autre pays</h2>
          <p className="mt-6 text-lg leading-8 text-white">
            Se rapprocher de sa famille ou de ses proches vivant à l'étranger, changer de travail ou créer son entreprise dans un contexte international, découvrir une nouvelle culture et un nouveau mode de vie…
          </p>
          <p className="mt-6 text-lg leading-8 text-white">
            Vous partez à l’étranger ? Nous nous occupons de votre déménagement à l’international depuis toutes les villes de France (Lyon, Paris, Toulouse, Bordeaux…).
          </p>
          <p className="mt-6 text-lg leading-8 text-white">
            Peu importe les raisons de votre déménagement, nous organisons de A à Z les déménagements internationaux (DOM-TOM, Europe, États-Unis, Maroc, Australie, Vietnam, Canada, Brésil, Chine, Inde…). Nos prestations n’ont pas de frontière !
          </p>
          <p className="mt-6 text-lg leading-8 text-white">
            En nous choisissant bénéficiez de :
          </p>
          <ul className="mx-6 mt-6 text-lg leading-8 text-white list-disc">
            <li >
              Notre savoir-faire de qualité
            </li>
            <li>
              Notre expérience
            </li>
            <li>
              Notre partenariat avec EMG (European Moving Group) et IAM (International Association of Movers), pour répondre à tous vos besoins.
            </li>
          </ul>
        </div>
        <div className="mt-10 flex items-center gap-x-6">
          <a
            href="/contact/"
            className="rounded-md bg-art-bleu px-3.5 py-2.5 text-lg font-normal text-art-beige shadow-sm hover:bg-art-bleu focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Déposez votre demande
          </a>
          <a href="/prestations/international/" className="text-lg font-normal leading-6 text-white">
            Votre déménagement à l’international <span aria-hidden="true">→</span>
          </a>
        </div>
      </div>
    </div>
  )
}
