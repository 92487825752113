import React from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { StaticImage } from "gatsby-plugin-image";

//import { Fragment } from 'react'
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

const particuliers = [
  { name: 'Déménagement particuliers', href: '/demenagement-particuliers/' },
  { name: 'Prestations', href: '/demenagement-particuliers/nos-prestations/' },
  { name: 'Boutique', href: '/boutique/' },
  { name: 'FAQ', href: '/demenagement-particuliers/faq/' },
]

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div className="h-full relative isolate overflow-hidden bg-art-beige">
      <div className="absolute inset-0 -z-10 h-full w-full object-cover brightness-75">
        <StaticImage
          className="absolute inset-0 -z-10 h-full w-full object-cover brightness-75"
          src="../../images/art-moval-home.jpg"
          alt="Logo Art Moval Déménagement"
        />
      </div>
      <div className="px-4 min-h-full lg:px-8">
        <nav
          className="flex items-center justify-between pt-6"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Art Moval</span>
              <div className="mr-4">
                <StaticImage
                  height={30}
                  src="../../images/Logo_ArtMoval_BeigeClair.png"
                  alt="Logo Art Moval Déménagement"
                />
              </div>
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-art-beige"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Ouvrir le menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-6 text-lg font-normal leading-6 text-art-beige">
            <a href="/nous-connaitre/">
              Qui sommes nous
            </a>
            <Popover className="relative">
              <PopoverButton className="inline-flex items-center gap-x-1">
                <span>Particuliers</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </PopoverButton>

              <Transition
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                  <div className="w-72 shrink rounded-xl bg-white p-4 text-lg font-normal leading-6 text-art-marron-fonce shadow-lg ring-1 ring-gray-900/5">
                    {particuliers.map((item) => (
                      <a key={item.name} href={item.href} className="block p-2 hover:text-art-beige-fonce">
                        {item.name}
                      </a>
                    ))}
                  </div>
                </PopoverPanel>
              </Transition>
            </Popover>
            <a href="/demenagement-entreprises/">
              Entreprises
            </a>
            <a href="/garde-meubles/">
              Garde-meubles
            </a>
            <a href="/contact/">
              Devis
            </a>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">

          </div>
        </nav>
        <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <Dialog.Panel
            focus="true"
            className="fixed inset-0 z-10 overflow-y-auto bg-art-beige px-6 py-6 lg:hidden"
          >
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Art Moval</span>
                <StaticImage
                  src="../../images/Logo_ArtMoval.png"
                  alt="Logo Art Moval Déménagement"
                  height={60}
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Fermer menu</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/25">
                <div className="space-y-2 py-6 -mx-3 block rounded-lg py-2 px-3 text-lg font-normal leading-7 text-art-marron-fonce hover:bg-gray-400/10">

                  <a href="/nous-connaitre/" className="py-2 block">
                    Qui sommes nous
                  </a>

                  <a href="/demenagement-particuliers/" className="py-2 block">
                    Déménagement de particuliers
                  </a>

                  <a href="/demenagement-particuliers/nos-prestations/" className="py-2 block pl-4">
                    Prestations
                  </a>
                  <a href="/boutique/" className="py-2 block pl-4">
                    Boutique
                  </a>
                  <a href="/demenagement-particuliers/faq/" className="py-2 block pl-4">
                    FAQ
                  </a>

                  <a href="/demenagement-entreprises/">
                    Déménagement d’entreprises
                  </a>

                  <a href="/garde-meubles/" className="py-2 block">
                    Garde-meubles
                  </a>
                  <a href="/contact/" className="py-2 block">
                    Devis
                  </a>
                </div>

              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
        <div className="mx-auto max-w-3xl py-12 sm:py-48 lg:py-56">
          <div className="text-center ">
            <h1 className="text-3xl font-bold tracking-tight text-art-beige sm:text-6xl">
              Art Moval : Votre entreprise de
              déménagement et garde-meubles
              à Lyon

            </h1>
            <h2 className="mt-6 text-2xl leading-8 text-art-beige font-normal">
              Services de déménagement sur mesure pour particuliers et entreprises à Lyon
            </h2>
            <p className="mt-6 text-lg leading-8 text-art-beige font-normal">
              Entreprise de déménagement et de garde-meubles.
              <br />
            </p>
            <p className="mt-6 text-lg leading-8 text-art-beige">
              Au départ ou à l’arrivée de Lyon et de la
              région Auvergne-Rhône-Alpes.
              <br /> Intervention partout en France et à
              l'étranger.<br />
              Pour les déménagements de particuliers, les transferts d’entreprises et de collectivités territoriales et le garde-meubles.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/contact/"
                className="rounded-md bg-art-bleu px-3.5 py-2.5 text-lg font-normal text-art-beige shadow-sm hover:bg-art-bleu focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
              >
                Demandez un devis
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
        <svg
          className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
        >
          <path
            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
            fillOpacity=".2"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9089FC" />
              <stop offset={1} stopColor="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
}
