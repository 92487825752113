import React from "react";
import { Disclosure } from '@headlessui/react'
import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/outline'
export default function SectionPartenaire() {


  const faqs_particuliers = [
    {
      question: "Pouvez-vous me donner une fourchette de prix pour déménager un logement ?",
      answer:
        "Nous ne pouvons pas fournir de fourchette de prix par téléphone pour déménager votre logement.Il est nécessaire de récolter préalablement des informations sur votre besoin et d'organiser une visite de notre commercial à domicile afin de réaliser un inventaire détaillé.Nous ne voulons pas vous donner de prix pour éviter les mauvaises surprises.Après notre visite, vous recevrez ensuite un devis précis dans les 48 heures, qui sera basé sur divers facteurs tels que le volume de biens à transporter, les conditions d'accès au lieu d’arrivée et de destination, la date du déménagement et les prestations souhaitées.",
    },
    {
      question: "Avez-vous des disponibilités de date sur le mois à venir pour vous occuper de notre déménagement ?",
      answer:
        "Nous nous efforçons toujours de satisfaire toutes les demandes, donc nous parvenons toujours à avoir de la disponibilité. Nous sommes en mesure de fournir des dates par téléphone si nécessaire.",
    },
    {
      question: "Combien de temps à l’avance doit-on vous valider le devis pour avoir une date de déménagement ?",
      answer:
        "Il n'y a pas de délai spécifique défini. Nous pouvons réaliser le déménagement d’une maison ou d’un appartement en urgence, mais certains facteurs doivent être pris en compte, comme les délais d'obtention d'autorisations municipales ou de stationnement. La période est également un élément à considérer. Par exemple, pendant la saison haute de juin à septembre, les entreprises de déménagement sont très sollicitées. Art Moval n’échappe pas à ce phénomène et cela peut réduire les disponibilités. Ainsi, il est conseillé de s'y prendre à l'avance, mais tout dépend également de notre planning.",
    },
    {
      question: "Quelles sont les modalités de paiement proposées par Art Moval sur ses prestations de déménagement ?",
      answer:
        "Pour tout service de déménagement à Lyon à destination d’un particulier, nous demandons des arrhes de 40% lors de la confirmation du devis, suivi du règlement du solde le jour de la livraison. Cependant, nous proposons également un règlement en 4 à 6 fois sans frais.",
    },
    {
      question: "Faites-vous régulièrement appel à des sous-traitants pour déménager un logement à Lyon ?",
      answer:
        "Nous n'avons jamais recours à la sous-traitance. Lorsque vous choisissez Art Moval, c'est Art Moval qui vous déménage. Nous n'employons pas non plus de travailleurs temporaires, sauf dans des circonstances très particulières.",
    },
    // More questions...
  ]

  const faqs_entreprises = [
    {
      question: "Quelle disponibilité de date avez-vous pour le transfert ou déménagement d’une entreprise ?",
      answer:
        "Nous sommes flexibles et prêts à vous assister dans l'organisation efficace de votre déménagement de bureaux, en nous adaptant à vos besoins spécifiques.",
    },
    {
      question: "Quelqu’un peut-il se déplacer pour réaliser un inventaire et estimer les besoins d’un transfert d’activité ?",
      answer:
        "Bien sûr. Un de nos commerciaux peut se déplacer au sein de votre entreprise pour effectuer un inventaire. C'est une étape essentielle avant l'établissement de tout devis. Cela nous permet de discuter des détails, de recenser vos besoins et de définir les prestations souhaitées.",
    },
    {
      question: "Comment assurez-vous la protection du matériel informatique pour le déménagement ? Est-ce à nous ou à vous de le faire ?",
      answer:
        "Nous utilisons des techniques d'emballage professionnelles ainsi que du matériel spécifique tel que des couvertures, des cartons et des bacs dédiés. Tous nos déménageurs sont formés et expérimentés. C'est notre équipe qui se charge d'emballer le matériel informatique pour garantir sa protection pendant le déménagement.",
    },
    // More questions...
  ]

  const faqs_meubles = [
    {
      question: "Quel est le prix d’un garde-meubles chez Art Moval à Lyon ?",
      answer:
        "Le coût est déterminé en fonction du volume de stockage ainsi que de la valeur du mobilier à assurer. Chez Art Moval, le tarif est de 5 € HT par mètre cube par mois, auquel s'ajoute le coût de l'assurance, équivalent à 0,35% de la valeur déclarée.",
    },
    {
      question: "Où est stocké mon mobilier dans votre garde-meubles lyonnais ?",
      answer:
        "Il est conservé dans des caisses-conteneurs qui sont scellées dès leur mise en garde-meubles. Ces caisses sont entreposées dans un entrepôt sécurisé.",
    },
    {
      question: "Quelle est la différence entre un selfstockage et un garde-meubles à Lyon ?",
      answer:
        "Le self-stockage offre un accès direct à vos biens, tandis que dans un garde-meubles, le client n'a pas d'accès. Si vous avez besoin d'accéder à vos affaires pendant la période de stockage, des frais de manutention sont généralement appliqués. Ces frais couvrent la manipulation des caisses pour les sortir et les ouvrir, ainsi que leur refermeture. Par ailleurs, les tarifs varient ; en règle générale, les self-stockages sont plus coûteux.",
    },
    {
      question: "Pourra-t-on accéder à nos biens stockés dans le garde-meubles ?",
      answer:
        "Dans un garde-meubles, cela n'est pas possible. Si vous désirez un accès libre à vos affaires, il est préférable d'opter pour un self-stockage.",
    },
    {
      question: "Combien de temps peut-on conserver ses biens en garde-meubles à Lyon ?",
      answer:
        "La durée est indéterminée. Vous n'êtes pas tenu de fixer une période de stockage à l’avance.",
    },
    {
      question: "Le garde-meubles d’Art Moval à Lyon est-il sécurisé ?",
      answer:
        "Absolument, le garde-meubles offre un environnement sécurisé pour vos biens personnels.",
    },
    // More questions...
  ]

  const faqs_aides = [
    {
      question: "Existe-t-il des aides financières pour le déménagement ?",
      answer:
        "Oui, plusieurs aides financières peuvent être disponibles en fonction de différentes situations. Par exemple, certaines municipalités offrent des subventions ou des aides pour les familles à faibles revenus ou les personnes en situation de handicap. De plus, il existe des dispositifs d'aide au déménagement pour les personnes bénéficiant de certains types d'allocations ou de prestations sociales. Les employeurs peuvent également proposer des aides financières ou des avantages pour les déménagements liés à un changement de lieu de travail. Il est recommandé de se renseigner auprès des administrations locales, des services sociaux ou des employeurs pour connaître les possibilités d'aides disponibles dans chaque cas particulier.",
    },

  ]




  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-7xl gap-y-16">
          <h2 className="text-4xl lg:text-6xl font-bold tracking-tight text-art-marron-fonce sm:text-6xl">
            Questions fréquentes sur le déménagement
            à Lyon
          </h2>
          <h3 className="text-2xl lg:text-2xl font-normal tracking-tight text-art-marron-fonce sm:text-6xl pt-8">
            Déménagements de particuliers
          </h3>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs_particuliers.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex text-lg w-full items-start justify-between text-left text-art-marron-fonce">
                        <span className="text-normal font-normal leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-lg leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
          <h3 className="text-2xl lg:text-2xl font-normal tracking-tight text-art-marron-fonce sm:text-6xl mt-16">
            Déménagements d'entreprises
          </h3>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs_entreprises.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex text-lg w-full items-start justify-between text-left text-art-marron-fonce">
                        <span className="text-normal font-normal leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-lg leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
          <h3 className="text-2xl lg:text-2xl font-normal tracking-tight text-art-marron-fonce sm:text-6xl mt-16">
            Garde-meubles
          </h3>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs_meubles.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex text-lg w-full items-start justify-between text-left text-art-marron-fonce">
                        <span className="text-normal font-normal leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-lg leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
          <h3 className="text-2xl lg:text-2xl font-normal tracking-tight text-art-marron-fonce sm:text-6xl mt-16">
            Aides
          </h3>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs_aides.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex text-lg w-full items-start justify-between text-left text-art-marron-fonce">
                        <span className="text-normal font-normal leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-lg leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div >
  );
}
